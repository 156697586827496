import React from "react";

import Layout from "../../components/layout";
import SEO from "../../components/seo";

import Grid from "@material-ui/core/Grid";

import style from "../../components/Dashboards/style";
import {checkAuth} from "../../helpers/auth";
import Results from '../../components/DashboardResults';
import ReportsFiltering, { possibleDates, getPresetDate } from "../../components/ReportsFiltering";
import axios from "axios";
import Loading from "../../components/Miscellaneous/Loading";
import { Select, MenuItem, Input } from "@material-ui/core";
import moment from 'moment';
import ScrollTopLeft from "../../components/Miscellaneous/scrollTopLeft";

moment.locale('en', {
    week: {
        dow: 1 // Monday is the first day of the week.
    }
});
export const groupByOptions = ['Day', 'Week', 'Month', 'Day of Week'];

export function GroupBy(props) {
    return (
        <Grid key={`group_by`} style={{width: '23%', margin: '1%'}}>
            <div style={{
                maxWidth: '100%',
                marginLeft: '10px'
            }}>
                <span>Group By</span>
                <Select
                    style={{
                        width: '100%'
                    }}
                    labelId={`group_by`}
                    value={props.groupBy}
                    onChange={(e) => props.groupByFn(e.target.value)}
                    input={<Input />}
                >
                    {props.options.map((name, j) => (
                        <MenuItem key={`group_by_${j}`} value={name}>
                            {name}
                        </MenuItem>
                    ))}
                </Select>
            </div>
        </Grid>
    );
}

class Sales extends React.Component {
    results = React.createRef();
    filtering = React.createRef();
    state = {
        config: null,
        selectedDimensions: {
            dimension1: 'Client',
            dimension2: 'Product',
            startDate: moment().startOf('week').format('YYYY-MM-DD'),
            endDate: moment().format('YYYY-MM-DD')
        },
        additionalFilters: {
            "Include refunds": false
        },
        loading: true,
        columns: [],
        data: [],
        groupBy: 'Day'
    }
    componentDidMount() {
        checkAuth('media-planner');
        axios.get(`${process.env.SERVER_ENDPOINT}/reportsConfig/sales`).then(response => {
            this.setState({
                ...this.state,
                config: response.data,
                loading: false
            })
            this.loadData();
        });

    }

    loadData() {
        this.setState({
            ...this.state,
            loading: true
        })
        axios.get(process.env.SERVER_ENDPOINT  + `${this.state.config.url}?${this.getDimensionParams() }&groupByTimePeriod=${this.state.groupBy}`).then(response => {
            this.setState({
                ...this.state,
                data: response.data.data,
                columns: response.data.columns,
                loading: false
            })
        })
    }

    onSelect(selected, additionalFilters) {
        this.setState({
            ...this.state,
            selectedDimensions: selected,
            additionalFilters: additionalFilters
        }, () => {
            this.results.current.loadData();
        })
        
    }

    getDimensionParams() {
        let str = '';
        for(let i in this.state.selectedDimensions) {
            if(typeof(this.state.selectedDimensions[i]) !== 'undefined') {
                if(typeof(this.state.selectedDimensions[i]) === 'string') {
                    str += `&${encodeURIComponent(i)}=${this.state.selectedDimensions[i]}`;
                } else {
                    str += `&${encodeURIComponent(i)}=${this.state.selectedDimensions[i].join(`&${i}=`)}`;
                }
            }
        }

        if(Object.keys(this.state.additionalFilters).length > 0) {
            for(let i in this.state.additionalFilters) {
                str += `&${encodeURIComponent(i)}=${this.state.additionalFilters[i]}`;
            }
        }
        return str;
    }


    groupBy(value) {
        let dims = Object.assign({}, this.state.selectedDimensions);
        switch(value) {
            case 'Day':
                dims.startDate = moment().add('day',  -7).format('YYYY-MM-DD')
                dims.endDate = moment().format('YYYY-MM-DD')
            break;

            case 'Week':
                dims.startDate = moment().add('week', -5).format('YYYY-MM-DD')
                dims.endDate = moment().format('YYYY-MM-DD')
            break;

            case 'Month':
                dims.startDate = moment().add('month', -12).format('YYYY-MM-DD')
                dims.endDate = moment().format('YYYY-MM-DD')
            break;

            case 'Day of Week':
                dims.startDate = moment().add('days', -30).format('YYYY-MM-DD')
                dims.endDate = moment().format('YYYY-MM-DD')
            break;
            default:
        }

        dims.dateMode = 'specific';
        dims.selectedPredefinedDate = '';

        for(let name of possibleDates) {
            let dates = getPresetDate(name);
            if(dates.startDate === dims.startDate && dates.endDate === dims.endDate) {
                dims.dateMode = 'predefined';
                dims.selectedPredefinedDate = name;
            }
        }

        this.filtering.current.setState({
            ...this.filtering.current.state,
            selected: dims
        });

        this.setState({
            ...this.state,
            groupBy: value
        })
    }

    render() {
        let groupingCols = [];
        [this.state.selectedDimensions.dimension1, 
            this.state.selectedDimensions.dimension2
        ].map(d => d ? groupingCols.push(d) : false)
        const highlightLines = this.state.additionalFilters && this.state.additionalFilters.visibleRows ? this.state.additionalFilters.visibleRows.filter(e=>e).length : 3;
        return (
            <Layout>
                <SEO title="Welcome"/>
                <Grid container style={style.mainContainer}>
                    <Grid container justify="center" spacing={2}>
                        {this.state.config !== null ? 
                        <div style={style.tabContainer}>
                            <div className={"salesReport " + (highlightLines==3 ? "three-lines" : highlightLines == 2 ? "two-lines":"single-line")}>
                                {this.state.loading ? 
                                    <Loading />
                                :
                                <>
                                <Results 
                                    ref={this.results}
                                    sort={false}
                                    columns={[]}
                                    dynamicDrillDownColumns={true}
                                    dynamicColumns={true}
                                    groupRows={3}
                                    drillDownFormatting={this.state.config.drillDownFormatting}
                                    drillDownOptions={{
                                        columns: [],
                                        queryParams: this.getDimensionParams() + `&groupByTimePeriod=${this.state.groupBy}`
                                    }}
                                    url={process.env.SERVER_ENDPOINT + this.state.config.url + `?${this.getDimensionParams() }&groupByTimePeriod=${this.state.groupBy}`}
                                    exportCSVConfig={{
                                        name:'sales',
                                        url: process.env.SERVER_ENDPOINT + this.state.config.url + `?${this.getDimensionParams() }&groupByTimePeriod=${this.state.groupBy}`
                                    }}
                                    columnsFormat={this.state.config.columnsFormat}
                                    filters={{
                                        selected: this.state.selectedDimensions, 
                                        additionalFilters: this.state.additionalFilters
                                    }}
                                    groupingOptions={{
                                        cols: groupingCols,
                                        startingRow: 0
                                    }}
                                >
                                    <h3>{this.state.config.title}</h3>
                                    <ReportsFiltering
                                        ref={this.filtering}
                                        onSelect={(selected, filters) => this.onSelect(selected, filters)}
                                        selected={{
                                            dimension1: 'Client',
                                            dimension2: 'Product',
                                            startDate: this.state.selectedDimensions.startDate,
                                            endDate: this.state.selectedDimensions.endDate
                                        }}
                                        type="sales"
                                        config={this.state.config}>
                                            <GroupBy 
                                                groupBy={this.state.groupBy}
                                                groupByFn={this.groupBy.bind(this)}
                                                options={groupByOptions}
                                            ></GroupBy>
                                    </ReportsFiltering>
                                </Results>
                                <ScrollTopLeft />
                                </>
                                }
                            </div>
                        </div>
                        : null}
                    </Grid>
                </Grid>
            </Layout>
        );
    }
}

export default Sales;
